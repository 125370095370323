<template>
  <div class="homePage">
    <div class="bannerWrap">
      <div class="banner-text">
        <p class="pl-name pl-name1">人力资源和社会保障部社会保障能力建设中心</p>
        <p class="pl-name pl-name2">家庭服务业（康养产业）职业技能培训服务平台</p>
        <p class="pl-second">
          <span class="text-line">优质的教学资源、</span>
          <span>参培机构、服务</span>
        </p>
      </div>
      <img
        src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/banner/bannerNo.png"
        alt="人力资源和社会保障部社会保障能力建设中心"
      >
    </div>

    <!-- 职业技能培训项目 -->
    <div class="vocationalSkills">
      <div class="container w1200">
        <div class="title">
          职业技能培训项目
        </div>

        <div class="topThree">
          <div
            v-for="(item, index) in vocSkillTopList"
            :key="index"
            class="vocSkillItem topThreeItem"
            @click="handleJumpProjectIntroRoute(item)"
          >
            <div class="imgWrap">
              <img :src="item.imgUrl">
            </div>
            <div class="textWrap">
              <div class="itemTitle">
                {{ item.title }}
              </div>
              <div class="itemDesc">
                {{ item.desc }}
              </div>
            </div>
          </div>
        </div>

        <div class="bottomFour">
          <div
            v-for="(item, index) in vocSkillBottomList"
            :key="index"
            class="vocSkillItem bottomFourItem"
            @click="handleJumpProjectIntroRoute(item)"
          >
            <div class="imgWrap">
              <img :src="item.imgUrl">
            </div>
            <div class="textWrap">
              <div class="itemTitle">
                {{ item.title }}
              </div>
              <div class="itemDesc">
                {{ item.desc }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 专项技能培训项目 -->
    <div class="specialSkills">
      <div class="container w1200">
        <div class="specialTitle">
          专项技能培训项目
        </div>

        <div class="topFour">
          <div
            v-for="(item, index ) in specialSkillTopList"
            :key="index"
            class="topFourItem specialSkillItem"
            @click="handleJumpProjectIntroRoute(item)"
          >
            <div class="imgWrap">
              <img :src="item.imgUrl">
            </div>
            <div class="descWrap">
              <div class="desc">
                {{ item.desc }}
              </div>
            </div>
          </div>
        </div>

        <div class="bottomThree">
          <div
            v-for="(item, index ) in specialSkillBottomList"
            :key="index"
            class="bottomThreeItem specialSkillItem"
            @click="handleJumpProjectIntroRoute(item)"
          >
            <div class="imgWrap">
              <img :src="item.imgUrl">
            </div>
            <div class="descWrap">
              <div class="desc">
                {{ item.desc }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 证书展示、培训标准 -->
    <div class="certAndTrainingStandard">
      <div class="container w1200">
        <!-- 证书展示 -->
        <div class="certWrap">
          <div class="certTitle">
            证书展示
          </div>
          <div class="certImgWrap">
            <img
              class="certImg"
              src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhengshu/cert11.png"
            >
            <img
              class="certImg"
              src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhengshu/cert22.png"
            >
            <img
              class="certImg"
              src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/renshehome/zhengshu/cert33.png"
            >
          </div>
          <div class="certQueryBtn">
            <a
              class="link"
              href="http://edu.mohrss.gov.cn/zscx.jsp?urltype=tree.TreeTempUrl&wbtreeid=1575"
              target="_blank"
            >
              证书查询
            </a>
          </div>
        </div>

        <!-- 培训标准 -->
        <div class="trainingStandardWrap">
          <div class="trainingTop clearfix">
            <div class="title">培训标准</div>
            <div class="more">
              <router-link
                class="moreText"
                to="/mall/standardList"
              >
                查看更多
              </router-link>
              <span class="moreIcon"></span>
            </div>
          </div>
          <ul class="trainList">
            <li
              v-for="(item, index) in trainStandardList"
              :key="item.id"
              class="trainListItem"
              @click="handleTrainStandardSeeDetail(item)"
            >
              <span class="serialNumber">
                0{{ index + 1 }}
              </span>
              <span class="trainItemTitle">
                {{ item.title }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 试听课 -->
    <div class="trialLesson">
      <div class="trialTitle">
        试听课
      </div>

      <!-- 培训项目名称 -->
      <ul class="courseList w1200 clearfix">
        <li
          v-for="item in trialCourseList"
          :key="item.id"
          class="courseListItem"
          :class="{checked: item.id === trialCheckedId}"
          @click="onTrialCourseItemClick(item)"
        >
          {{ item.courseName }}
        </li>
      </ul>

      <!-- 培训项目课程内容 -->
      <div class="trialCourseContent w1200 clearfix">
        <div
          v-if="trialCourseContentList.length"
          class="arrowLeft fl"
        >
          <i
            class="arrow"
            @click="handleCourseContentChange({direction: 'left'})"
          />
        </div>
        <div class="trialCourseContentListWrap fl clearfix">
          <ul
            ref="courseContentRef"
            class="trialCourseContentList clearfix"
            :style="courseContentListStyle"
          >
            <li
              v-for="item in trialCourseContentList"
              :key="item.courseId"
              class="trialCourseContentListItem clearfix"
            >

              <div class="courseDescWrap fl">
                <div class="courseLevel">
                  <div class="name">
                    {{ item.levelName }}
                  </div>
                  <div class="videoName desc">
                    {{ item.videoName }}
                  </div>
                </div>

                <div class="course">
                  <div class="name">课程简介</div>
                  <div
                    class="courseDesc desc"
                    :title="item.courseDesc"
                  >
                    {{ item.courseDesc }}
                  </div>
                </div>

                <div class="playButton">
                  <button
                    @click="handleVideoPlay(item)"
                    :disabled="item.isPlaying"
                  >
                    {{ item.isPlaying ? '播放中' : '播放课程' }}
                  </button>
                </div>
              </div>
              <div class="videoWrap fl">
                <video
                  class="video"
                  ref="videoRef"
                  controls
                  :src="item.videoUrl"
                  :poster="item.posterUrl"
                  @play="handleVideoStartPlay(item)"
                  @pause="handleVideoPause(item)"
                  @ended="handleVideoPlayEnded(item)"
                ></video>
                <transition name="el-fade-in">
                  <div
                    v-show="!item.isPlaying"
                    class="playIcon"
                    @click="handleVideoPlay(item)"
                  />
                </transition>
              </div>
            </li>
          </ul>
        </div>
        <div
          v-if="trialCourseContentList.length"
          class="arrowRight"
        >
          <i
            class="arrow"
            @click="handleCourseContentChange({direction: 'right'})"
          />
        </div>
      </div>
    </div>

    <!-- 合作机构入驻 -->
    <div class="institutionSettle">
      <div class="container w1200">
        <div class="insTitle">合作机构入驻</div>
        <ul class="insList">
          <li class="insListItem">
            <div class="top-text">
              <p>教学资源合作机构入驻</p>
              <p>如果你拥有课程资源，由此进入查看是否符合培训标准，申请成为教学合作机构即可获得利益。</p>
            </div>
            <button
              class="settleBtn"
              @click="handleJumpInstituteRoute"
            >
              入驻申请
            </button>
          </li>
          <li class="insListItem">
            <div class="top-text">
              <p>参培机构入驻</p>
              <p>如果你的学员或员工需要获得优质培训服务，由此进入申请为参培机构即可获得。</p>
            </div>
            <button
              class="settleBtn"
              @click="handleJumpInstituteRoute"
            >
              入驻申请
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="footer">
      <div class="con">
        <p>推荐使用Microsoft Edge、Chrome、360浏览器</p>
        <span>主办单位：人力资源和社会保障部社会保障能力建设中心</span>
        <span>京ICP备 13009107号-3</span>
        <span>京公网安备 11010502033661</span>
        <span>技术支持：伟东云教育集团</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  vocSkillTopList,
  vocSkillBottomList,
  specialSkillTopList,
  specialSkillBottomList,
  trialCourseList,
} from "../common/data";
const COURSE_LIST_WRAP_WIDTH = 1120;

export default {
  data() {
    return {
      vocSkillTopList,
      vocSkillBottomList,
      specialSkillTopList,
      specialSkillBottomList,
      trainStandardList: [],
      trialCourseList,
      // 试听高亮选中
      trialCheckedId: 1,
      // 试听轮播移动style样式
      trialCourseListStyle: {},
      // 试听课程内容索引
      trialCourseContentIndex: 0,
      // 播放按钮显隐
      showPlayBtnIcon: true,
    };
  },
  computed: {
    // 试听的课程内容数组
    trialCourseContentList() {
      return this.trialCourseList.find(
        (item) => item.id === this.trialCheckedId
      ).children;
    },
    courseContentListStyle() {
      const { trialCourseContentList, trialCourseListStyle } = this;

      return {
        width: `${trialCourseContentList.length * COURSE_LIST_WRAP_WIDTH}px`,
        ...trialCourseListStyle,
      };
    },
  },
  created() {
    this.getStandardList();
  },
  methods: {
    async getStandardList() {
      const res = await this.$api.mall.getWorkUrl();

      if (res.success && res.data && res.data.length) {
        this.trainStandardList = res.data.slice(0, 5).map((item) => ({
          id: item.work_url,
          title: item.work_url_name,
        }));
      }
    },

    handleJumpProjectIntroRoute(item) {
      if (item.code) {
        this.$router.push({
          path: "/projectIntro",
          query: {
            code: item.code,
            gongzhong:item.gongzhong,
            spec:item.spec,
            id:item.id
          },
        });
      } else {
        this.$toast("正在建设中，敬请期待…");
      }
    },

    // 培训标准详情查看
    handleTrainStandardSeeDetail({ id }) {
      const { href } = this.$router.resolve({
        path: "/mall/pdfDetail",
        query: {
          id,
        },
      });

      window.open(href, "_blank");
    },

    // 试听课-培训项目切换
    onTrialCourseItemClick(item) {
      const { trialCourseContentIndex } = this;

      // 切换之前将视频播放标志值恢复初始
      if (this.trialCourseContentList.length) {
        this.trialCourseContentList[trialCourseContentIndex].isPlaying = false;
      }

      this.trialCourseContentIndex = 0;
      this.trialCourseListStyle = {};
      this.trialCheckedId = item.id;
    },

    handleCourseContentChange({ direction }) {
      const {
        trialCourseContentList: { length },
        trialCourseContentIndex,
      } = this;
      const arrLastIndex = length - 1;

      // 将视频播放进行暂停
      this.$refs.videoRef[trialCourseContentIndex].pause();
      this.trialCourseContentList[trialCourseContentIndex].isPlaying = false;

      if (direction === "left") {
        if (trialCourseContentIndex === 0) {
          this.trialCourseContentIndex = arrLastIndex;
        } else {
          this.trialCourseContentIndex--;
        }
      } else {
        if (trialCourseContentIndex === arrLastIndex) {
          this.trialCourseContentIndex = 0;
        } else {
          this.trialCourseContentIndex++;
        }
      }

      this.trialCourseListStyle = {
        transform: `translate3d(${
          -this.trialCourseContentIndex * COURSE_LIST_WRAP_WIDTH
        }px, 0, 0)`,
      };
    },

    handleVideoPlay(item) {
      if (item.isPlaying) return;

      item.isPlaying = true;
      this.$refs.videoRef[this.trialCourseContentIndex].play();
    },

    // 视频播放事件
    handleVideoStartPlay(item) {
      item.isPlaying = true;
    },

    // 视频播放暂停
    handleVideoPause(item) {
      item.isPlaying = false;
    },

    handleVideoPlayEnded(item) {
      item.isPlaying = false;
    },

    handleJumpInstituteRoute() {
      this.$router.push("/institutionsIn");
    },
  },
};
</script>

<style lang="less">
@import "../asset/css/global.less";
</style>
<style lang="less" scoped>
@import "../asset/css/index.less";
</style>
<style lang="less" scoped>
.bannerWrap{
  position:relative;
  .banner-text{
    position:absolute;
    left:50%;
    width:1200px;
    top:0;
    height:480px;
    color:#fff;
    padding-top:92px;
    box-sizing:border-box;
    transform:translateX(-50%);
    padding-left:28px;
    .pl-name1{
      font-size:35px;
      line-height:54px;
      width:600px;
      margin-bottom:24px;
    }
    .pl-name2{
      font-size:26px;
      line-height:54px;
      width:600px;
    }
    .pl-second{
      margin-top:60px;
      span{
        font-size:22px;
      }
    }
    .text-line{
      position:relative;
      &::after{
        position: absolute;;
        left:6px;
        bottom:-20px;
        background:#fff;
        height:4px;
        content:'';
        width:148px;
      }
    }
  }
}
</style>

